@import '../../variables';

.toast-container {
  max-width: 93%;
  height: tovw(62px);
  padding: 0 tovw(16px);
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 14;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }
}
