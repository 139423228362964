@import '../../variables';

.home-points {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: tovw(11px);
  text-align: left;

  &__info {
    display: flex;
    min-width: fit-content;
    flex-direction: column;
    padding: tovw(10px) tovw(5px) tovw(10px) 0;

    &__upper-text {
      color: $blue;
      font-style: normal;
      font-weight: bold;
      font-size: tovw(24px);
    }

    &__bottom-text {
      font-style: normal;
      font-weight: $font_weight_medium;
      font-size: tovw(16px);
      padding-right: tovw(10px);
      color: $white;
    }
  }

  &__button {
    margin-left: tovw(20px);
  }
}
