@import '../../variables';

.app-submenu__settings {
  background-color: rgb(0 0 0 / 0%) !important;
  justify-content: center;
  opacity: 1;
  z-index: 10;
  position: absolute;
  left: tovw(58.67px);
  pointer-events: auto;

  .navbar-item__text {
    opacity: 1;
  }
}
