@import '../../variables';

.watchlist-overlay {
  width: 100%;
  height: 100%;
  background: $black-96;
  backdrop-filter: blur(10%);

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;

    .content-icon {
      width: tovw(32px);
      height: tovw(32px);
      position: absolute;
      top: tovw(58.67px);
      left: 50%;
      transform: translateX(-50%);

      svg {
        width: tovw(32px);
        height: tovw(32px);
      }
    }

    .content-text {
      display: flex;
      flex-direction: column;
      margin-right: tovw(16px);

      p {
        font-size: tovw(64px);
        color: $tan;
        font-weight: $font-weight-medium;
        text-align: right;

        span {
          color: $orange;
        }
      }
    }

    .content-poster {
      background-color: $charcoal;
      width: tovw(170.67px);
      height: tovw(266.67px);
      margin-right: tovw(16px);
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .data {
        position: absolute;
        bottom: tovw(5.34px);
        left: tovw(5.34px);
        width: tovw(50px);
        height: tovw(26px);
        background: $black-60;
        font-weight: bold;
        font-size: tovw(12px);
        line-height: tovw(26px);
        text-transform: uppercase;
        text-align: center;
        color: #f1dac4;
      }

      .fallback-title {
        position: absolute;
        text-align: start;
        top: tovw(100px);
        left: tovw(15px);

        .fallback-title-text {
          color: $white;
          opacity: 0.5;
          width: 100%;
          word-break: break-word;
          overflow-wrap: break-word;

          &.standard {
            font-size: $context-title-font-size;
            font-weight: $font-weight-medium;
            text-transform: none;
            width: calc(100% - #{tovw(24px)});
          }
        }
      }
    }

    .content-buttons {
      display: flex;
      flex-direction: column;

      a,
      button {
        margin-bottom: tovw(16px);
      }
    }
  }
}
