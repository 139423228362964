@import '../../variables';

%progress-width {
  height: inherit;
  margin: 0;
}

.progress-bar {
  background-color: $black-60;
  overflow: hidden;

  &.card {
    height: tovw(6px);
    padding: 0;
    width: 96%;

    div {
      @extend %progress-width;

      border-radius: tovw(1.5px);
      background-color: $white;
    }
  }

  &.button {
    height: tovw(4px);
    margin: tovw(2px) 0 tovw(2px);
    padding: 0;

    div {
      @extend %progress-width;

      background-color: $white;
      border-radius: $border-radius-small 0 0 $border-radius-small;
    }
  }
}
