@import '../../variables';

$icon-width: tovw(26px);
$icon-height: tovw(32px);
$bottom-margin: tovw(60px);

.pin-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $fullscreen-height;
  margin: 0;
  padding: 0;
  width: 100%;
  background: url($background-blur) no-repeat fixed center $charcoal;

  &__description {
    color: $tan;
  }

  h1 {
    color: $orange;
    font-size: $grid-title-font-size;
    font-weight: $font-weight-light;
    margin: tovw(85px) 0 0 0;
    letter-spacing: -0.03em;
  }

  span {
    font-size: $regular-font-size;
    font-weight: $font-weight-medium;
    line-height: tovw(20px);
    margin: 0;
    text-align: center;
    width: tovw(447.34px);
  }

  &__lock-icon {
    color: $tan;
    height: $icon-height;
    margin-top: tovw(60px);
    width: $icon-width;
  }

  &__buttons {
    bottom: $bottom-margin;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    button:first-child {
      margin-right: tovw(12px);
    }

    &.faded {
      button:first-child {
        opacity: 0.25;
      }
    }
  }
}
