@import '../../variables';

.rewards-auth {
  height: $fullscreen-height;
  display: flex;
  text-align: center;
  position: relative;
  width: 100vw;
  background-color: $charcoal;

  &__background-image {
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/welcome_start.png');
    background-size: cover;
    position: absolute;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      filter: blur(5px);
    }
  }

  &__signed-in-opted-out {
    width: 100%;
    margin-top: tovw(80px);
    margin-left: tovw(80px);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    z-index: 2;

    &__info {
      h1 {
        color: #009ffd;
        line-height: tovw(62px);
        font-weight: $font-weight-light;
        font-size: tovw(62px);
      }

      h3 {
        font-weight: $font-weight-medium;
        font-size: tovw(28px);
        color: $white;
        line-height: tovw(10px);
      }

      .check-circle-icon {
        color: $tan;
        width: tovw(12px);
        height: tovw(12px);
        margin-right: tovw(5px);
      }

      .rewards-benefits {
        color: $tan;
        list-style: none;
        margin-top: tovw(10px);
        padding-inline-start: 0;

        li {
          margin-bottom: tovw(15px);
          font-size: tovw(16px);
        }
      }
    }

    &__buttons {
      display: flex;
      margin-top: tovw(50px);

      &__cancel {
        margin-left: tovw(20px);
      }
    }

    &__tos {
      color: $tan;
      font-weight: $font-weight-medium;
      font-size: tovw(14px);
      margin-top: tovw(110px);
    }
  }

  &__signed-out {
    width: 100%;
    margin-top: tovw(55px);
    margin-left: tovw(80px);
    position: fixed;
    text-align: left;
    z-index: 2;

    h1 {
      color: #009ffd;
      line-height: tovw(62px);
      font-weight: $font-weight-light;
      font-size: tovw(62px);
    }

    h3 {
      font-weight: $font-weight-medium;
      font-size: tovw(32px);
      color: $white;
    }

    p {
      color: $tan;
      font-size: tovw(24px);
    }

    &__tos {
      color: $tan;
      font-weight: $font-weight-medium;
      font-size: tovw(14px);
      margin-top: tovw(25px);
    }

    &__buttons {
      display: flex;
      margin-top: tovw(55px);

      &__create-account {
        text-decoration: none;
        margin-right: tovw(20px);
      }

      &__sign-in {
        text-decoration: none;
        margin-right: tovw(20px);
      }
    }
  }

  .tos {
    color: $tan;
  }
}
