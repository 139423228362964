@import '../../variables';

@supports not (display: grid) {
  .see-all-card {
    background-position: center;
    background-size: cover;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center !important;
    font-weight: $font-weight-bold !important;
  }

  .see-all-card__poster-container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .see-all-card__fallback-text.standard {
    margin-top: tovw(50px);
    font-weight: bold;
    transform: translateY(-50%);
  }

  .hover-enabled {
    &:hover {
      .see-all-card__fallback-text.standard {
        transform: translateY(-50%) scale(1.06);
      }
    }
  }
}
