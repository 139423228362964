@import '../../variables';

.navbar-logo-container {
  padding: $navbar-logo-padding;
  overflow: hidden;
  transition: none;
  transform: translateX(-100%);
  opacity: 0;

  svg {
    width: $navbar-logo-width;
    height: $navbar-logo-height;
  }

  .hover-disabled &.open {
    opacity: 1;
    transform: translateX(0);
  }
}

.hover-enabled .navbar:hover .navbar-logo-container {
  opacity: 1;
  transform: translateX(0);
}
