@import '../../variables';

$half: 50%;
$icon-width: tovw(32px);
$text-height: tovw(113px);

.still-watching-screen {
  background: $exit-background;
  background-size: cover;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  &__icon {
    color: $tan;
    height: $icon-width;
    left: calc(50% + (#{$icon-width} / 2));
    margin-left: -$icon-width;
    position: absolute;
    top: tovw(58.7px);
    width: $icon-width;
  }

  &__content {
    display: flex;
    left: $half;
    margin-right: -$half;
    position: absolute;
    top: $half;
    transform: translate(-$half, -$half);

    &__left {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      margin-right: tovw(16px);
      text-align: right;
      width: calc(50% - #{tovw(16px)});

      h1 {
        color: $primary-font-light;
        font-size: $grid-title-font-size;
        line-height: $grid-title-font-size;
        font-weight: $font-weight-light;
        margin: 0;
      }
    }

    &__right {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 50%;

      button {
        margin-bottom: tovw(24px);
        white-space: nowrap;

        svg {
          height: tovw(16px);
          width: tovw(16px);
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__footer {
    bottom: tovw(88px);
    color: $primary-font-light;
    font-size: tovw(16px);
    font-weight: $font-weight-medium;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;

    p {
      margin: 0;
    }
  }
}
