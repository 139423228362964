@import '../../variables';

.sponsored-free-pass-image {
  display: none;

  &.visible {
    display: block;
  }

  iframe {
    width: tovw(68px);
    height: tovw(68px);
    border: none;
    pointer-events: none;
  }
}
