@import '../../variables';

.redeemed-reward-toast {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: $black-60;
  border-radius: tovw(5.34px);
  margin-top: tovw(41px);
  padding: tovw(16px);
  height: tovw(97.34px);
  width: tovw(600px);

  &__info {
    &__upper-text {
      color: $blue;
      font-weight: $font-weight-medium;
    }

    &__bottom-text {
      color: $tan;
      font-weight: $font_weight_medium;
    }

    p {
      font-size: tovw(24px);
      margin: 0;
      padding: 0;

      span {
        color: $orange;
      }
    }
  }

  svg {
    color: $blue;
    margin-right: tovw(16px);
    height: tovw(23px);
    width: tovw(20px);
  }
}
