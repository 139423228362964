.app {
  display: flex;
}

#console-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
}

#console-container > div {
  height: 100vh;
  overflow: auto;
}

.with-console {
  transform: scale(0.7) translateX(-21%);
  transform-origin: top center;
}

.with-console.full-screen {
  opacity: 0.2;
}

#console-container.full-screen {
  width: 100%;
}
