@import '../../variables';

// Local Variables
$details-card-width: tovw(512px);
$overlay-margin: $border-radius-large;
$originals-card-size: tovw(336px);

%title-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.content-card {
  background-position: center;
  background-size: cover;
  border-radius: $border-radius-content-card;
  display: flex;
  flex-direction: column;
  margin: 0 $card-column-margin;
  max-height: $standard-card-height;
  min-height: $standard-card-height;
  min-width: $standard-card-width;
  position: relative;
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: $border-radius-content-card;
    box-shadow: inset 0 0 0 tovw(1px) $border-black;
    transition: none;
  }

  &__originals-title-block {
    display: -webkit-box;
    position: absolute;
    margin: 0;
    color: $white;
    font-size: tovw(40px);
    font-weight: $font-weight-bold;
    line-height: tovw(40px);
    z-index: 4;
    width: calc(100% - #{tovw(40px)});
    text-align: left;
    bottom: $border-radius-large;
    left: $border-radius-large;
    max-height: tovw(120px);
    padding-bottom: tovw(2px);
    overflow: hidden;
    hyphens: auto;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    transition: none;
  }

  .hover-disabled div:not(.swiper-slide) > &:focus,
  .hover-enabled &:hover {
    .content-card__poster-container {
      transform: scale(1.06);
    }

    & .content-card__originals-title-block {
      color: $orange;
    }

    &.details .content-card__poster-container,
    &.originals .content-card__poster-container,
    & .content-card__originals-title-block {
      transform: scale(1.03);
    }

    &::after {
      box-shadow: $content-card-focused-border;
      transform: scale(1.06);
    }

    &.details::after,
    &.originals::after {
      transform: scale(1.03);
    }
  }

  &__poster-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    background-color: $charcoal;
    border-radius: $border-radius-content-card;

    picture {
      display: flex;
      height: 100%;
      width: 100%;
    }

    img {
      border-radius: $border-radius-content-card;
      max-height: $standard-card-height;
      min-height: $standard-card-height;
      object-fit: cover;
      transition: none;
      width: 100%;
    }

    &__locked-icon {
      color: $orange;
      height: tovw(16px);
      position: absolute;
      right: tovw(6px);
      top: tovw(6px);
      width: tovw(12px);
    }

    &__seen-icon {
      color: $orange;
      height: tovw(16px);
      position: absolute;
      right: tovw(6px);
      top: tovw(6px);
      width: tovw(12px);
    }

    &__episode-overlay {
      @include set-black(background, 0.6);

      border-radius: $border-radius-small;
      color: $tan;
      font-size: $small-font-size;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      line-height: $small-font-size;
      padding: tovw(8px);
      z-index: 2;
      position: absolute;
      left: tovw(6px);
      bottom: tovw(6px);
      border: solid 1px $badge-outline; // in 1920 is 1px. The value is in px to avoid less than 1px in 1280
    }
  }

  &__fallback-text {
    color: $white;
    opacity: 0.5;
    position: inherit;
    width: 100%;
    word-break: break-word;
    overflow-wrap: break-word;

    &.standard {
      font-size: $context-title-font-size;
      font-weight: $font-weight-medium;
      margin: 0 0 0 tovw(12px);
      text-transform: none;
      width: calc(100% - #{tovw(24px)});
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 7;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
  }

  &__fallback-title-text {
    color: $white;
    font-size: $context-title-font-size;
    font-weight: $font-weight-bold;
    opacity: 0.5;
    position: inherit;
  }

  &__locked-icon {
    color: $orange;
    height: tovw(16px);
    position: absolute;
    right: tovw(6px);
    top: tovw(6px);
    width: tovw(12px);
  }

  &__title {
    color: $orange;
    font-size: $small-font-size;
    font-weight: $font-weight-bold;
    min-height: tovw(33px);
    line-height: tovw(14px);
    top: tovw(24.33px);
    width: $standard-card-width;
    opacity: 0;
    position: relative;
    text-transform: uppercase;
    visibility: hidden;

    p {
      overflow: hidden;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      margin: 0;
      visibility: visible;
    }
  }

  &__title-block {
    @extend %title-container;
    @include set-black(background, 0.6);

    align-items: flex-start;
    border-radius: $border-radius-small;
    color: $white;
    flex-direction: column;
    gap: $border-radius-normal;
    height: fit-content;
    margin: $overlay-margin;
    min-height: tovw(180px);
    padding: $overlay-margin;
    position: absolute;
    bottom: 0;
    width: auto;

    h4 {
      color: $tan;
      font-size: $regular-font-size;
      font-weight: $font-weight-medium;
      line-height: tovw(20px);
      margin: 0;
    }

    div {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: $main-title-font-size;
      font-weight: $font-weight-bold;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      line-height: $main-title-font-size;
      overflow: hidden;
      padding-bottom: tovw(10px);
      text-decoration: none;
    }
  }

  &__overlay {
    background: linear-gradient(180deg, $black-0 0%, $black-60 100%);
    display: flex;
    flex-direction: column;
    height: $originals-card-size;
    width: 100%;
    z-index: 1;
    border-radius: $border-radius-content-card;

    &__badge {
      @extend %centered-flex;
      @include set-black(background, 0.6);

      border-radius: $border-radius-small;
      color: $primary-font-dark;
      font-size: $small-font-size;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      height: fit-content;
      justify-content: flex-start;
      line-height: $small-font-size;
      margin: $overlay-margin;
      padding: tovw(8px);
      z-index: 2;
      position: absolute;
      top: $border-radius-large;
      left: $border-radius-large;
      border: solid 1px $badge-outline; // in 1920 is 1px. The value is in px to avoid less than 1px in 1280

      svg {
        margin-right: tovw(4px);
      }
    }

    img {
      align-self: center;
      box-shadow: inset 0 0 0 tovw(5px) rgba($white, 0.15);
      display: block;
      height: tovw(110px);
      margin: $overlay-margin;
      object-fit: contain;
      width: 80%;
      z-index: 2;
    }
  }

  &.standard {
    color: $primary-font-dark;
    justify-content: center;
    max-width: $standard-card-width;
    text-decoration: none;
    margin-bottom: tovw(32px);

    .left {
      justify-content: left;
    }

    .inFocus .swiper-slide-active &,
    .hover-disabled div:not(.swiper-slide) > &:focus,
    .hover-enabled &:hover {
      .content-card__title {
        margin: calc(#{tovw(8px)} + #{$standard-card-height}) tovw(2px) 0
          tovw(-2px);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.details {
    max-height: $standard-card-height;
    max-width: $details-card-width;
    min-width: $details-card-width;

    .content-card__title-block {
      flex-direction: column;
      min-height: fit-content;
      transition: none;

      div {
        padding-bottom: 0;
      }

      .title {
        font-size: $medium-title-font-size;
        line-height: $medium-title-font-size + ($medium-title-font-size * 0.2);
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .desc {
        color: $tan;
        font-size: $regular-font-size;
        font-weight: $font-weight-medium-bold;
        line-height: tovw(20px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .inFocus &,
    .hover-disabled div:not(.swiper-slide) > &:focus,
    .hover-enabled &:hover {
      .content-card__title-block .title {
        color: $orange;
      }

      .content-card__title {
        max-width: $details-card-width;
        position: absolute;
        top: calc(#{tovw(12px)} + #{$standard-card-height});
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.originals {
    max-height: $originals-card-size;
    max-width: $originals-card-size;
    min-height: $originals-card-size;
    min-width: $originals-card-size;
    width: $originals-card-size;
    height: $originals-card-size;

    .content-card__overlay {
      transition: none;

      .content-card__title-block {
        max-height: tovw(180px);
      }

      .content-card__space {
        height: tovw(62px);
      }
    }

    .content-card__title {
      max-width: $originals-card-size;
      position: absolute;
      top: calc(#{tovw(12px)} + #{$originals-card-size});
      margin-top: tovw(200px);
    }

    .inFocus .swiper-slide-active &,
    .hover-disabled div:not(.swiper-slide) > &:focus,
    .hover-enabled &:hover {
      .content-card__overlay {
        transform: scale(1.03);
      }

      .content-card__title {
        opacity: 1;
        visibility: visible;
      }
    }

    img {
      max-height: $originals-card-size;
    }
  }
}
