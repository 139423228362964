@import '../../variables';

$pointer-size: tovw(16px);

.pin-modal {
  &__keypad {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 tovw(27px) 0;
    padding: 0;
  }

  &__digits {
    color: $white;
    display: flex;
    margin: tovw(13.34px) tovw(3.34px) tovw(10px);
    height: tovw(120px);
    width: tovw(133.32px);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &.active {
      color: $orange;

      svg {
        visibility: visible;
      }

      input {
        border-bottom: tovw(2px) solid $orange;
      }
    }

    input {
      background: none;
      border: none;
      border-bottom: tovw(2px) solid $white;
      color: inherit;
      font-size: $grid-title-font-size;
      height: 100%;
      outline: none;
      width: 100%;
      text-align: center;
    }

    div {
      align-self: flex-end;
      border: tovw(1px) solid $white;
      color: $white;
      height: 0;
      width: 100%;
    }

    svg {
      height: $pointer-size;
      visibility: hidden;
      width: $pointer-size;
    }
  }
}
