@import '../../variables';

.enable-css-transitions {
  .all-episodes__episode .all-episodes__episode__thumbnail {
    transition: transform 0.2s ease-in-out;
  }

  .content-card {
    &::after {
      transition: transform 0.2s ease-in-out;
    }

    &__originals-title-block {
      transition: transform 0.2s ease-in-out;
    }

    &__poster-container {
      transition: transform 0.2s ease-in-out;
    }

    &.details .content-card__title-block {
      transition: transform $delayed-animation;
    }

    &.originals .content-card__overlay {
      transition: transform $delayed-animation;
    }
  }

  + .backdrop {
    transition: opacity $delayed-animation;
  }

  .navbar-logo-container {
    transition: transform $delayed-animation, opacity $delayed-animation;
  }

  .navbar-item__text {
    transition: opacity $delayed-animation;
  }

  .toggle-switch label::after {
    transition: transform 0.3s;
  }

  .player-controls {
    transition: opacity $delayed-animation;
  }

  .not-swiper {
    transition: transform 200ms ease;
  }
}
