@import '../../variables';

// Local Dimensions
$content-top-padding: $content-bottom-padding;
$content-width: tovw(667px);
$hero-height: tovw(592px);
$hero-height-collapsed: tovw(324px);

.hero {
  $self: &;

  color: $white;
  font-family: $font, Arial, sans-serif;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  min-height: $hero-height;

  &::before {
    background: radial-gradient(at top left, $charcoal 14%, $black-0 33.3%);
    content: '';
    display: block;
    height: 33.3%;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: -1;
  }

  .circle__progress {
    height: tovw(30px);
    left: tovw(12px);
    position: absolute;
    top: tovw(11px);
    width: tovw(30px);

    .CircularProgressbar-path {
      stroke: $charcoal;
    }

    .CircularProgressbar-trail {
      stroke: $orange;
    }

    &__secondary-color {
      .CircularProgressbar-path {
        stroke: $white;
      }

      .CircularProgressbar-trail {
        stroke: none;
      }
    }

    &__text {
      font-size: tovw(12px);
      text-align: center;
      color: $charcoal;

      &__secondary-color {
        color: $white;
      }
    }

    svg.CircularProgressbar {
      height: tovw(30px);
      width: tovw(30px);
    }
  }

  .circle__progress--padding {
    margin-left: tovw(30px);
  }

  &__blends-container {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    width: calc((100% * 2) / 3);
    z-index: -4;

    div {
      height: 100%;
      width: 100%;
    }

    &__tan-glow {
      background: radial-gradient(
        50% 200% at 100% 100%,
        rgba($tan, 0.25) 0%,
        rgba($tan, 0) 100%
      );
      position: inherit;
      right: 0;
      top: 0;
    }

    &__blue-glow {
      background: radial-gradient(
        50% 200% at 100% 0%,
        rgb(0 159 253 / 25%) 0%,
        rgb(0 159 253 / 0%) 100%
      );
      right: 0;
    }
  }

  &__background {
    height: 100%;
    overflow: hidden;
    position: absolute;
    text-align: right;
    width: 100%;
    z-index: -5;

    &::before {
      background: $black-40;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__image {
      height: tovw(700px);
      min-width: 80%;
      width: auto;
    }
  }

  &--background-gradient {
    background: linear-gradient(90deg, $charcoal 62.3%, $black-0 100%);
    height: 100%;
    position: absolute;
    top: 0;
    width: calc(100% / 3);
    z-index: -4;
  }

  &__logo-container {
    @extend %crackle-logo-container;
    @include set-black(background, 0.6);

    border-radius: $border-radius-normal;
    padding: tovw(10.7px);
    position: absolute;
    right: $content-left-padding;
    top: $content-top-padding;
  }

  &__logo {
    height: auto;
    width: tovw(90px);
  }

  &__metadata {
    display: block;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin: 0;
    padding-left: $content-left-padding;
    padding-top: $content-top-padding;
    width: $content-width;
    padding-bottom: tovw(
      148.67px
    ); // to compensate negative margin from the first content-row

    z-index: 2;
  }

  &__context-title {
    color: $primary-font-light;
    font-size: $context-title-font-size;
    font-weight: $font-weight-medium;
    line-height: tovw(30px);
    margin: 0;
    margin-bottom: tovw(16px);
    overflow: hidden;
    hyphens: auto;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__featured-title {
    font-size: $main-title-font-size;
    font-weight: $font-weight-bold-title;
    line-height: tovw(50px);
    margin: 0;
    padding-bottom: tovw(16px);
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    hyphens: auto;
    -webkit-box-orient: vertical;
    max-height: tovw(103px);
  }

  &__ratings-tags {
    &--inner-container {
      align-items: center;
      color: $metadata-color;
      display: flex;
      height: tovw(22px);
      flex-direction: row;
      font-size: $small-font-size;
      font-weight: normal;
      line-height: $small-font-size;
      margin: 0;
      width: $content-width;

      p {
        background: $primary-dark;
        border: tovw(1px) solid $border-black;
        border-radius: $border-radius-small;
        color: $white;
        font-weight: $font-weight-bold;
        padding: tovw(8px);
        font-size: $small-font-size;
        line-height: $small-font-size;
      }

      ul {
        display: flex;
        margin-left: tovw(8px);
        padding: 0;

        li {
          font-size: $small-font-size;
          list-style-position: outside;
          margin: 0 tovw(10px);
          text-indent: tovw(-5px);
        }
      }

      ul > li:first-child {
        list-style-type: none;
      }
    }
  }

  &__buttons {
    display: flex;
    margin-top: tovw(16px);
    position: relative;

    a {
      text-decoration: none;
      margin-right: tovw(11px);
    }
  }

  &__description {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: $regular-font-size;
    font-weight: $font-weight-medium;
    -webkit-line-clamp: 3;
    max-width: tovw(600px);
    overflow: hidden;
    text-overflow: ellipsis;
    width: $content-width;

    p {
      line-height: tovw(20px);
      font-size: tovw(16px);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  &__collapsible-container {
    opacity: 1;
  }

  &.collapsed {
    min-height: $hero-height-collapsed;

    .hero {
      &__background {
        &::before {
          background: $black-60;
        }
      }

      &__blends-container {
        opacity: 0.5;
      }

      &__metadata {
        opacity: 0.5;
        padding-bottom: tovw(
          122px
        ); // to compensate negative margin from the first content-row
      }
    }

    #{$self} {
      &__collapsible-container {
        opacity: 0;
        height: 0;
      }
    }
  }

  &__trailer,
  &__trailer.bitmovinplayer-container {
    width: 80%;
    position: absolute;
    right: 0;
    top: 0;

    &.loading {
      display: none;
    }

    &::before {
      background: $black-40;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

    video {
      object-fit: cover;
    }
  }

  &--bottom-fade {
    background: linear-gradient(0deg, $charcoal 62.3%, $black-0 100%);
    height: tovw(60px);
    margin-top: tovw(-40px);
    position: relative;
    z-index: -4;
  }
}
