@import '../../variables';

.home-free-pass {
  background: $blue;
  color: $charcoal;
  display: flex;
  align-items: center;
  padding: tovw(8px);
  height: tovw(32px);
  width: fit-content;
  justify-content: center;
  font-size: tovw(16px);
  line-height: tovw(16px);
  text-align: right;
  border-radius: tovw(2px);

  &__bold-text {
    font-weight: $font-weight-bold;
  }
}

.loading-placeholder {
  display: flex;
  align-items: center;
  height: tovw(50px);
  width: fit-content;
  justify-content: center;
}
