@import '../../variables';

.reward-toast {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  margin: tovw(25px) auto;
  align-items: center;
  justify-content: center;
  background-color: $black-60;
  border-radius: tovw(5.34px);
  padding: tovw(16px);
  height: tovw(100px);

  &__icon {
    width: tovw(32px);
  }

  &__info {
    width: fit-content;
    max-width: 97%;

    &__text {
      color: $tan;
      font-weight: $font-weight-medium;

      &.bottom_line_text {
        margin-top: tovw(5.3px);
      }
    }

    p {
      font-size: tovw(24px);
      line-height: tovw(30px);
      margin: 0;
      padding: 0;
      -webkit-line-clamp: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: $blue;
      }
    }
  }

  svg {
    color: $blue;
    height: tovw(23px);
    width: tovw(20px);
  }
}
