@import '../../variables';

.main {
  align-items: center;
  color: $primary-font-light;
  display: flex;
  flex-direction: column;
  height: $fullscreen-height;
  padding-left: $navbar-width-closed;
  width: 100%;
  position: fixed;
  overflow: hidden;

  &.hover-enabled {
    overflow-y: auto;
  }

  &.full-width {
    left: 0;
    width: 100%;
    padding-left: 0;
  }
}
