@import '../../variables';

$black-80: rgba($charcoal, 0.8);
$margin-top-rows-container: tovw(-148.67px);

.home {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  z-index: 0;
  position: relative;

  &__small-free-pass {
    position: absolute;
    right: tovw(58.6667px);
    top: tovw(58.6667px);
  }

  &__hero {
    flex-shrink: 0;

    &__logo-container {
      @extend %crackle-logo-container;
      @include set-black(background, 0.8);

      border-radius: $border-radius-normal;
      padding: tovw(10.7px);
      position: fixed;
      right: tovw(58.6667px);
      bottom: tovw(58.6667px);
      z-index: 3;
    }

    &__logo {
      height: auto;
      width: tovw(90px);
    }
  }

  &::before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 9.16vw;
    height: 100%;
    z-index: 5;
    background: linear-gradient(90deg, $charcoal 55%, $black-0 100%);
    pointer-events: none;
  }

  &__content-rows {
    flex-shrink: 0;
    margin-top: $margin-top-rows-container; // to give space to scroll down
    z-index: 2;

    &.hero-collapsed {
      margin-top: tovw(-190px);
      padding-top: tovw(62px);
    }

    &__spinner {
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      display: flex;
      height: tovw(334px);
    }
  }

  &__points-bar-container {
    padding-left: $content-left-padding;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: tovw(-38.7px);
    margin-bottom: tovw(38.7px);
  }

  .standard-spacing {
    margin-top: 0;
    margin-bottom: tovw(53px);
  }

  .placeholder-hero {
    height: tovw(592px);
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    display: flex;

    &__spinner {
      margin-top: $margin-top-rows-container;
    }
  }

  .placeholder-hero-collapsed {
    height: tovw(324px);
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    display: flex;

    &.no-hero {
      height: tovw(280px);
    }
  }
}
