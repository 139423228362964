@import '../../variables';

.free-pass-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: tovw(10px) tovw(15px) tovw(10px) tovw(15px);
  height: tovw(110px);
  width: fit-content;
  justify-content: center;

  &__icon {
    margin-top: tovw(7px);
    margin-right: tovw(15px);

    svg {
      height: tovw(30px);
      width: tovw(32px);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: tovw(10px) tovw(5px) tovw(10px) tovw(5px);
  }
}

.border {
  @include set-black(background, 0.8);

  border-radius: tovw(5.3334px);
  border: 1px solid $border-black;
}

.bottom-text {
  text-align: left;
  font-style: normal;
  font-weight: $font_weight_medium;
  max-width: 70ch;
  font-size: tovw(17px);
  line-height: tovw(20px);
  padding-right: tovw(10px);
  color: $white;
}

.small-size {
  height: tovw(90px);
}

.upper-text {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: tovw(25px);
  line-height: tovw(40px);
  color: $blue;
}
