@import '../../variables';

@supports not (display: grid) {
  .content-card {
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: unset;
    text-decoration: none;
    position: relative;
    align-items: center;
    justify-content: center !important;
  }

  .content-card__poster-container {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .content-card__fallback-text.standard {
    text-transform: none;
    width: fit-content;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  .hover-enabled {
    &:hover {
      .content-card__fallback-text.standard {
        transform: translateY(-50%) scale(1.06);
      }
    }
  }

  .content-card.details .content-card__title-block {
    width: 95%;
    left: 0;
  }
}
