@import '../../variables';

$icon-width: tovw(48px);
$text-height: tovw(226px);

.error-screen {
  background: $charcoal;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  .alert-icon {
    color: $red;
    height: tovw(32px);
    left: calc(50% + (#{$icon-width} / 2));
    margin-left: tovw(-48px);
    position: absolute;
    top: tovw(60px);
    width: tovw(32px);
  }

  &__content {
    position: relative;
    width: 100%;

    &__left {
      position: fixed;
      top: calc(50% - (#{$text-height} / 2));
      width: 50%;

      h1 {
        color: $red;
        font-size: $grid-title-font-size;
        font-weight: $font-weight-light;
        margin: 0;
        text-align: right;
        line-height: tovw(70px);
      }
    }

    &__right {
      float: right;
      left: 50%;
      margin: tovw(12px) 0;
      position: fixed;
      top: calc(50% - (#{$text-height} / 2));
      width: 20%;

      button {
        margin: tovw(4px) 0 tovw(16px) tovw(24px);
      }
    }
  }

  &__footer {
    bottom: tovw(60px);
    color: $red;
    font-size: tovw(16px);
    font-weight: $font-weight-medium;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;

    p {
      margin: 0;
    }
  }
}
