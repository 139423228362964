@import '../../variables';
@import './pin-confirm';

.pin-error {
  @extend .pin-confirm;

  width: 100vw;
  color: $red;

  &__icon {
    @extend .pin-confirm__icon;

    color: $red;
  }

  &__info {
    @extend .pin-confirm__save-info;

    h1 {
      color: $red;
    }

    button {
      margin-bottom: tovw(16px);
    }

    &.multiline {
      left: 50%;
      position: absolute;

      h1 {
        line-height: tovw(60px);
        text-align: right;
      }
    }
  }
}
