@import '../../variables';

$half: 50%;
$icon-width: tovw(32px);
$separation: tovw(16px);

.sort-modal {
  @include set_black(background, 0.96);

  backdrop-filter: blur(#{tovw(20px)});
  height: $fullscreen-height;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;

  &__item__container {
    display: flex;
    left: $half;
    margin-right: -$half;
    position: absolute;
    top: $half;
    transform: translate(-$half, -$half);
  }

  &__icon {
    height: tovw(28px);
    left: calc(50% - (#{$icon-width} / 2));
    position: absolute;
    top: tovw(62px);
    width: $icon-width;
    color: $tan;
  }

  &__text {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: $separation;
    text-align: right;
    width: calc(50% - #{tovw(16px)});

    h1 {
      color: $primary-font-light;
      font-size: $grid-title-font-size;
      line-height: $grid-title-font-size;
      font-weight: $font-weight-light;
      margin: 0;
    }

    span {
      color: $primary-font-dark;
      font-size: $grid-title-font-size;
      margin: 0;
      hyphens: none;
      word-break: break-word;
      overflow-wrap: break-word;
      min-width: tovw(520px);
      font-weight: $font-weight-light;
    }
  }

  &__options {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 50%;

    a {
      text-decoration: none;
    }
  }

  &__selector {
    margin-bottom: tovw(24px);

    .hover-disabled &:focus,
    .hover-enabled &:hover {
      svg {
        color: $charcoal;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &.selected {
      border: tovw(2px) solid $orange;
      color: $orange;

      svg {
        color: $orange;
      }
    }
  }

  &__checkbox {
    align-items: center;
    display: inline-flex;
    position: relative;

    span {
      font-size: $regular-font-size;
      font-weight: $font-weight-bold;
      margin-left: tovw(8px);
    }

    input {
      appearance: none;
      background-color: transparent;
      border: tovw(1px) solid $white;
      border-radius: $half;
      display: none;
      height: $regular-font-size;
      outline: none;
      width: $regular-font-size;

      &:checked {
        background-color: $orange;
        border: none;
      }

      &:checked + span::before {
        color: $charcoal;
        content: '\2713';
        display: block;
        left: 0.2rem;
        position: absolute;
        text-align: center;
        top: 0.1rem;
      }
    }

    svg {
      margin-right: 0;
      width: tovw(16px);
      height: tovw(16px);
    }
  }
}
