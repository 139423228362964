.fading-background {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/welcome_start.png');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    left: 0;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
    animation-name: fade-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-duration: 3.5s;
    position: absolute;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
