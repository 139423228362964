@import '../../variables';
@import './pin-modal';

.pin-confirm {
  @extend .pin-modal;

  &__icon {
    @extend .pin-modal__lock-icon;
  }

  &__save-info {
    align-items: center;
    display: flex;
    flex-direction: row;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    h1 {
      color: $tan;
      font-weight: $font-weight-light;
      margin: 0 tovw(16px) 0 0;
    }
  }

  &__description {
    color: $tan;
  }

  p {
    bottom: $bottom-margin;
    font-size: $regular-font-size;
    font-weight: $font-weight-medium;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    text-align: center;
  }
}
