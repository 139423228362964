@import '../../variables';

.navbar {
  align-items: center;
  color: $primary-font-light;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  transition: none;

  .hover-disabled &.open,
  .hover-enabled &:hover,
  .hover-enabled &.app-submenu.open,
  .hover-enabled &.app-submenu__settings.open {
    align-items: flex-start;
    background-color: $primary-dark;
    overflow-y: auto;
    width: $navbar-width-open;
    transition: none;
  }
}
