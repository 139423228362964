@import '../../variables';

.modal-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background: rgba($charcoal, 0.96);
  z-index: 13;

  &.visible {
    display: flex;
  }
}
