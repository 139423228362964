@import '../../variables';

.watchlist-toast {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-color: $black-90;
  border-radius: tovw(5.34px);
  padding: tovw(16px);

  p {
    font-size: tovw(24px);
    color: $tan;
    font-weight: $font-weight-medium;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    &.highlight {
      color: $orange;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::after {
      content: ' ';
      margin-right: tovw(6.66px);
    }
  }

  svg {
    color: $orange;
    margin-right: tovw(16px);
    height: $regular-font-size;
    width: $regular-font-size;
    flex-shrink: 0;
  }
}
