@import '../../variables';

.menus-wrapper {
  display: flex;
  height: $fullscreen-height;
  position: fixed;
  width: 100vw;
  z-index: 12;
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.hover-enabled {
  .app-menu {
    width: $navbar-width-closed;
  }
}

.app-menu {
  z-index: 12;
  pointer-events: auto;

  + .backdrop {
    content: '';
    display: block;
    position: fixed;
    z-index: 11;
    background: $black-60;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100vw;
    height: 100%;
    pointer-events: none;
  }

  .hover-disabled &.open,
  .hover-enabled &:hover {
    align-items: flex-start;
    overflow-y: auto;
    width: $navbar-width-open;
    background: none;

    + .backdrop .app-menu__background {
      transform: translateX(0);
    }
  }

  .hover-disabled &.open,
  .hover-enabled &:hover,
  .hover-enabled &.app-submenu.open {
    + .backdrop {
      opacity: 1;
    }
  }

  &__upper {
    .app-logo {
      color: $primary-font-dark;
    }
  }

  &__upper,
  &__middle,
  &__lower {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .rewards-item {
    color: $blue;
  }

  .menu-rewards {
    .hover-disabled &.navbar-item:focus,
    .hover-enabled &.navbar-item:hover {
      background-color: $blue;

      .menu-points-bar {
        color: $blue;
        background-color: $charcoal;
        font-weight: $font-weight-medium;
      }
    }
  }

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $primary-dark;
    width: $navbar-width-open;
    height: 100%;
    transform: translateX(-100%);
  }
}
