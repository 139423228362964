@import '../../variables';

.sponsored-ad {
  display: block;
  height: fit-content;
  width: fit-content;
  z-index: 5;

  &.mediumPlaceholder {
    width: tovw(260px);
    height: tovw(28.4px);
  }

  &.largePlaceholder {
    width: tovw(369.4px);
    height: tovw(1451.4px);
  }

  &__frame {
    &__large {
      width: tovw(369.4px);
      height: tovw(147.4px);
    }

    &__medium {
      width: tovw(260px);
      height: tovw(24px);
    }
  }

  iframe {
    border: none;
    pointer-events: none;
  }

  &.video-container__sponsored-ads,
  &.content-details__ads {
    height: tovw(44px);
  }

  &.video-container__sponsored-ads iframe,
  &.content-details__ads iframe {
    width: tovw(176px);
    height: tovw(44px);
  }

  .hero__metadata & {
    margin-bottom: tovw(16px);
    margin-left: tovw(2px);
  }
}
