@import '../../variables';

.navbar-item {
  $self: &;

  align-items: center;
  color: $metadata-color;
  display: flex;
  height: tovw(59px);
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  &.active-route {
    color: $primary-font-dark;
  }

  .hover-disabled &:focus,
  .hover-enabled &:hover {
    background-color: $primary-light;
    color: $secondary-font-dark;
  }

  svg {
    margin-right: tovw(11px);
    width: tovw(16px);
    height: auto;
  }

  &__text {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: $font, Arial, sans-serif;
    font-size: tovw(16px);
    font-weight: $font-weight-bold;
    opacity: 0;
    transition: none;
    line-height: tovw(18px);

    &__points-bar {
      margin-left: tovw(45px);
    }
  }

  &__icon-wrapper {
    padding: tovw(32px) 0 tovw(32px) tovw(21.33px);
    display: flex;
    align-items: center;
  }

  .hover-disabled &.open,
  .hover-enabled .app-submenu &.open {
    #{$self}__text {
      opacity: 1;
      visibility: visible;
    }

    .hover-disabled &:focus,
    .hover-enabled &:hover {
      outline: none;
    }
  }
}

.hover-enabled .navbar:hover .navbar-item__text {
  opacity: 1;
  visibility: visible;
}
