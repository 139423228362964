@import '../../variables';

.menu-points-bar {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: tovw(11px);
  text-align: center;
  border-radius: tovw(360px);
  min-width: fit-content;
  width: tovw(65px);
  height: tovw(22px);
  color: $charcoal;
  background-color: $blue;
}
