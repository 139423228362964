@import 'variables';

:root {
  --default-font-family: #{$font}, -apple-system, blinkmacsystemfont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --default-cc-font-size: 1.64vw;
}

html,
body {
  margin: 0;
  overflow: hidden;
  font-size: tovw(16px);
  background: transparent;
}

::-webkit-scrollbar {
  display: none;
}

.sb-show-main.sb-main-padded {
  padding: 0;
}

body .app,
.storybook-container {
  width: 100vw;
  height: $fullscreen-height;
  overflow: hidden;
  position: relative;
}

* {
  box-sizing: border-box;

  &:focus,
  &:hover {
    outline: none;
  }
}

body,
button,
input {
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

.main {
  background: url($background-blur) no-repeat fixed center $charcoal;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-gradients {
  width: 100vw;
  height: $fullscreen-height;
  position: relative;
  z-index: 0;

  .blue-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $gradient-top-right;
    opacity: 0.75;
    z-index: 2;
  }

  .orange-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: $gradient-bottom-left;
    opacity: 0.15;
    z-index: 1;
  }
}
